// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/buddy/openbook-github-io/workspaces/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---packages-gatsby-theme-andybrace-src-templates-project-js": () => import("/buddy/openbook-github-io/workspaces/packages/gatsby-theme-andybrace/src/templates/project.js" /* webpackChunkName: "component---packages-gatsby-theme-andybrace-src-templates-project-js" */),
  "component---packages-gatsby-theme-andybrace-src-pages-404-mdx": () => import("/buddy/openbook-github-io/workspaces/packages/gatsby-theme-andybrace/src/pages/404.mdx" /* webpackChunkName: "component---packages-gatsby-theme-andybrace-src-pages-404-mdx" */),
  "component---packages-gatsby-theme-andybrace-src-pages-index-mdx": () => import("/buddy/openbook-github-io/workspaces/packages/gatsby-theme-andybrace/src/pages/index.mdx" /* webpackChunkName: "component---packages-gatsby-theme-andybrace-src-pages-index-mdx" */),
  "component---packages-gatsby-theme-andybrace-src-pages-projects-mdx": () => import("/buddy/openbook-github-io/workspaces/packages/gatsby-theme-andybrace/src/pages/projects.mdx" /* webpackChunkName: "component---packages-gatsby-theme-andybrace-src-pages-projects-mdx" */),
  "component---src-pages-about-mdx": () => import("/buddy/openbook-github-io/workspaces/site/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/buddy/openbook-github-io/workspaces/site/.cache/data.json")

